import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import HeroBgImg from '../components/heroBgImg'

import './amazon-tool-bundles.css'

const BundlesPage = ({ data }) => (
  <Layout>
    
    <Helmet>
      <title>AlphaDex | Amazon Tool Bundles</title>
      <meta name="description" content="AlphaDex is your go to spot for finding the best tools and resources for selling on amazon. These bundles will help you with your specific needs." />
      <meta name="keywords" content="amazon, selling" />
    </Helmet>

    <HeroBgImg imgUrl={data.bundleBG.childImageSharp.fluid} >
      <h1 style={{textAlign: 'center'}}>Bundles for specific amazon needs</h1>
    </HeroBgImg>
    <div style={{
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      margin: '50px 0'
    }}>
      <div className="bundle">
        <span role="img" aria-label="baby face" style={{ 
          fontSize: 85,
          display: 'block',
          lineHeight: 1.2,
          textAlign: 'center'
        }}>👶</span>
        <h2 style={{ textAlign: 'center' }}>Looking to start selling on Amazon?</h2>
        <div className="bundle-info" >
          <p><a href="https://www.junglescout.com/?ref=thealphadex.com" target="_blank" rel="noopener noreferrer">Jungle Scout</a> - Product research tool to track potential product categories</p>
          <p><a href="https://www.youtube.com/watch?v=igDk6yHwIuk?ref=thealphadex.com" target="_blank" rel="noopener noreferrer">Intro to Selling</a> - Watch this video once you have a product idea</p>
          <p><a href="http://theamazingseller.com/category/podcast/?ref=thealphadex.com" target="_blank" rel="noopener noreferrer">The Amazing Seller Podcast</a> - Over 500 episodes covering different seller pain points</p>
        </div>
      </div>
        
      <div className="bundle">
        <span role="img" aria-label="rocketship" style={{
            fontSize: 85,
            display: 'block',
            lineHeight: 1.2,
            textAlign: 'center'
          }}>🚀</span>
        <h2 style={{ textAlign: 'center' }}>Want to quickly launch your product? </h2>
        <div className="bundle-info" >
          <p><a href="https://viral-launch.com/launches.html?ref=thealphadex.com" target="_blank" rel="noopener noreferrer">Viral Launch</a> - Runs specific promotions to help get you to page 1</p>
          <p><a href="https://www.jumpsend.com/#jsWantToLaunch?ref=thealphadex.com" target="_blank" rel="noopener noreferrer">Jump Send</a> -  Helps grow reviews and traffic through email marketing and promotions</p>
          <p><a href="https://www.ecomcrew.com/amazon-product-launch-strategy-focusing-on-off-amazon-traffic/?ref=thealphadex.com" target="_blank" rel="noopener noreferrer">Case Study</a> - Complete walkthrough on tips for launching products</p>
        </div>
      </div>
        
      <div className="bundle">
        <span role="img" aria-label="magnifying glass" style={{
            fontSize: 85,
            display: 'block',
            lineHeight: 1.2,
            textAlign: 'center'
          }}>🔍</span>
        <h2 style={{ textAlign: 'center' }}>Want to optimize your listings?</h2>
        <div className="bundle-info" >
          <p><a href="https://virtuousgraphics.com/?ref=thealphadex.com" target="_blank" rel="noopener noreferrer">Virtuous Graphics</a> -  One stop shop for graphic design, photgraphy and video</p>
          <p><a href="https://app.junglemarket.com/en/listings/529362-complete-optimized-listing-with-enhanced-brand-content-copy?ref=thealphadex.com" target="_blank" rel="noopener noreferrer">Enhanced Brand Content</a> - Top rated EBC freelancer at a reasonable price</p>
          <p><a href="https://www.amztracker.com/index_en.php#a_aid=5a3be1588707e?ref=thealphadex.com" target="_blank" rel="noopener noreferrer">AMZ Tracker</a> - Offensive and defensive software for keyword rankings</p>
        </div>
      </div>
        
      <div className="bundle">
        <span role="img" aria-label="chart going up" style={{
          fontSize: 85,
          display: 'block',
          lineHeight: 1.2,
          textAlign: 'center'
        }}>📈</span>
        <h2 style={{ textAlign: 'center' }}>Need to get past a sales plateau?</h2>
        <div className="bundle-info" >
          <p><a href="https://splitly.com/?ref=thealphadex.com" target="_blank" rel="noopener noreferrer">Splitly</a> -  A/B split testing to methodically grow your sales</p>
          <p><a href="https://www.feedbackfive.com/?ref=thealphadex.com" target="_blank" rel="noopener noreferrer">FeedbackFive</a> - Review managment and feedback software</p>
          <p><a href="https://app.junglemarket.com/en/listings/528916-we-will-audit-your-amazon-product-listing-to-increase-sales?ref=thealphadex.com" target="_blank" rel="noopener noreferrer">Get your Listing Auditted</a> - A team of experts who will point out whats wrong with your listing and how to help you fix it</p>
        </div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  {
    bundleBG: file(relativePath: {eq: "bundles-bg.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default BundlesPage
